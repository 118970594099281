.App {
  text-align: center;
  margin-bottom: 0;
  max-height: 100%;
}

.Banner-image {
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 20px;
  max-width: 125px;
  max-height: 125px;
  overflow: hidden;
  border-radius: 100px;
}

.App div {
  margin-bottom: 15px;
}

.Me-banner {
  padding-top: 45px;
  position: relative;
  border-radius: 180px;
  max-height: 200px;
  /*border-color: black;*/
  /*border-width: 1px;*/
  /*border-style: solid;*/
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  position: absolute;
  top: 16vh;
  /*margin-bottom: 40vh;*/
  /*padding-bottom: 39vh;*/
  filter: brightness(2.5) sepia(0) hue-rotate(200deg) saturate(.2);

}

.App-logo svg path {
  fill: black;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}

.Me-banner[bounce='1'] {
  animation: bounce 1s 1;
}

@keyframes bounce {
  20% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(-25deg);
  }
  /*75% {*/
  /*  transform: rotate(5deg);*/
  /*}*/
  80% {
    transform: rotate(0deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Hover-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: float-effect infinite 2s linear;

  box-shadow: 0px 469px 76px -121px rgba(0, 0, 0, 0.25);
  border-radius: 500px;
}

.Hover-container:hover {
  animation-play-state: paused;
}

@keyframes float-effect {
  20% {
    transform: translateY(3px);
  }
  55% {
    transform: translateY(-3px);
  }
  80% {
    transform: translateY(3px);
  }
}

.App-header {
  /*background-color: #282c34;*/
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.About-me p, h1, h2 {
  font-size: 24px;
  margin: 0;
  color: black;
  font-weight: normal;
}

.Social-icons {
  display: flex;
}

.Social-icon {
  padding: 5px;
}

.Social-icon {
  height: 45px;
}

.Social-icon:hover {
  box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.42);
  border-radius: 25px;
  padding: -4px;
  border-width: 1px;
}

.Footer {
  margin-top: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-top: black 2px solid;
  padding: 15px;
  font-size: 18px;
}

.Email-Signup-Form input {
  margin: 0 10px;
  border-radius: 4px;
}

.Email-Signup-Form-btn {
  background-color: black;
  color: white;
  border-radius: 5px;
}

.msg-alert {
  margin-bottom: 0;
}

.msg-alert p {
  margin-bottom: 0;
}
